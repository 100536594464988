import React from 'react';

const TermsAndConditionsHeader = () => {
  return (
    <div className="terms-and-conditions-sub-header-container">
      <div className="sub-header-title">Terms of Use</div>
      <div className="sub-header-date">Updated Date: Feb 23, 2023</div>
    </div>
  );
};

export default TermsAndConditionsHeader;

import React from 'react';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import SEO from '@components/SEO';

import TermsAndConditionsHeader from './TermsAndConditionsHeader';
import PrivacyPolicyAndTermAndConditionsFooter from '@components/Layout/Footer/PrivacyPolicyAndTermAndConditionsFooter';
const TermsAndConditions = () => {
  return (
    <Layout isMobileComingSoon={false}>
      <SEO title="Terms and Conditions" />
      <div className="terms-and-conditions-container">
        <TermsAndConditionsHeader />
        <div className="terms-and-conditions-content">
          <div className="content-title">Overview</div>
          <div className="description">
            Please read this agreement carefully before accessing or using the
            information and services available through the Wan PNG websites
            (“Sites”). By accessing or using the Sites, you agree to be bound by
            the terms and conditions below. Wan PNG may modify this agreement at
            any time, and such modifications shall be effective immediately upon
            posting the modified agreement on the Sites. Wan PNG also has a
            comprehensive Privacy Policy for users of the Sites, which can be
            viewed separately.
          </div>
          <div className="content-title">Copyright and trademarks</div>
          <div className="description">
            Wan PNG is the property of Kumul Petroleum Holdings Limited
            (“KPHL”). Copyright in the material and trademarks on Wan PNG Sites
            are owned by KPHL unless otherwise indicated and you agree not to
            infringe any intellectual property rights owned by KPHL.
          </div>
          <div className="content-title">
            Information for personal, non-commercial use only
          </div>
          <div className="description">
            You agree that information contained on Wan PNG is for personal use
            only and may not be sold, redistributed or used for any commercial
            purpose (this includes but is not limited to the use of Advertiser
            contact details for unsolicited commercial correspondence and the
            information available via our Employer and Institution subdomains (
            <b>
              <a
                className="link-text"
                target="_blank"
                href="https://employer.wanpng.com"
              >
                https://employer.wanpng.com
              </a>
            </b>{' '}
            and{' '}
            <b>
              <a
                className="link-text"
                target="_blank"
                href="https://institution.wanpng.com"
              >
                https://institution.wanpng.com{' '}
              </a>
            </b>
            ). You may download material from Wan PNG Sites for your personal,
            non-commercial use only, provided you keep intact all copyright and
            other proprietary notices.
            <br />
            <br />
            You may not modify, copy, reproduce, republish, upload, post,
            transmit or distribute in any way any material from Wan PNG Sites
            including code and software. You must not use Wan PNG Sites for any
            purpose that is unlawful or prohibited by these terms of use.
            <br />
            <br />
            You may not use data mining, robots, screen scraping, or You may not
            use data mining, robots, screen scraping, or similar automated data
            gathering, extraction or publication tools on Wan PNG Sites
            (including without limitation for the purposes of establishing,
            maintaining, advancing or reproducing information contained on our
            Sites on your own websites or in any other publication), except with
            our prior written consent.
          </div>
          <div className="content-title">
            Information does not represent professional advice
          </div>
          <div className="description">
            You acknowledge and agree that information published by Wan PNG or
            its publishers is intended to provide general information in summary
            form on career advice, course listings, employment resources, legal
            and other issues.
            <br />
            <b>
              Wan PNG does not warrant the accuracy or completeness of such
              information.
            </b>
            <br />
            <br />
            Wan PNG does not endorse or recommend any of the jobs, business or
            self-employment opportunities advertised on its Sites and Wan PNG
            strongly recommends that prior to entering into any agreement with
            any of the employers or institutions, that you obtain your own
            independent advice as appropriate. It is solely your responsibility
            to evaluate the accuracy, completeness and usefulness of all
            opinions, advice, services, merchandise and other information
            provided through the Sites or on the Internet generally. <br />
            <br />
            In no event will Wan PNG be liable to you or anyone else for any
            decision made or action taken by you or anyone else in reliance upon
            any information contained on or omitted from the Sites.
          </div>
          <div className="content-title">No guarantees of job vacancy</div>
          <div className="description">
            Wan PNG gives no guarantee to you of the continued availability of
            any particular job advertised on the Sites and will not be liable to
            you should a job advertiser have filled the vacancy at any time
            prior to removal of the job advertisement from the Sites.
            <br />
            <br />
            Whilst Wan PNG takes efforts to ensure that jobs advertised Whilst
            Wan PNG takes efforts to ensure that jobs advertised are for actual
            job vacancies, it gives no guarantee to you that every job
            advertisement represents an actual job vacancy.
          </div>
          <div className="content-title">
            No guarantees or endorsements of Sites or services we link to or
            that link to us
          </div>
          <div className="description">
            Wan PNG provides no warranty to you that the services generally
            available through its Sites will be uninterrupted or error-free or
            that defects in the service will be corrected. You also understand
            that Wan PNG cannot and does not guarantee or warrant to you that
            files available for downloading through the Sites or delivered via
            electronic mail through the Sites will be free of infection or
            viruses, worms, Trojan horses or other code that manifest
            contaminating or destructive properties. You are responsible for
            implementing sufficient procedures and checkpoints to satisfy your
            particular requirements for accuracy of data input and output, and
            for maintaining a means external to the Sites for the reconstruction
            of any lost data.
          </div>
          <div className="content-title">
            Our rights to use information you send us
          </div>
          <div className="description">
            Wan PNG welcomes ideas and feedback from you about all aspects of
            the Sites. You agree that Wan PNG may reproduce, distribute,
            transmit, create derivative works of, and publicly display any
            materials and other information (including ideas for new or improved
            products and services) that you submit to any public areas of the
            Sites or by email to Wan PNG. (Please read our Privacy Policy for
            more information as to how we deal with information you send to us.)
          </div>
          <div className="content-title">
            Your responsibility for your login details
          </div>
          <div className="description">
            To become a registered user, you must provide a password and an
            email address to Wan PNG directly or instead register using your
            Google, Facebook or LinkedIn accounts (“login details”). You are
            entirely responsible if you do not maintain the confidentiality of
            your login details. Furthermore, you are entirely responsible for
            any and all activities that occur under your account. You may change
            your password at any time by following instructions by clicking
            Settings. You may also delete information associated with your
            account at your convenience.
            <br />
            <br />
            You agree to immediately notify Wan PNG of any unauthorized use of
            your login details or any other breach of security relevant to Wan
            PNG known to you.
          </div>
          <div className="content-title">No misrepresentations allowed</div>
          <div className="description">
            You agree that it is a condition on your use of the Wan PNG Sites
            and of any other services provided by Wan PNG or through the Sites
            that you will not through any act or omission (including but not
            limited to creating a profile on the Wan PNG Sites) mislead or
            deceive others.
          </div>
          <div className="content-title">General provisions</div>
          <div className="description">
            If any part of this agreement is held invalid that part shall be
            severed from this agreement and the remainder of this agreement will
            continue to be valid and enforceable.
            <br />
            <br />
            Termination of this agreement will not end those provisions that are
            capable of surviving the ending of this agreement.
            <br />
            <br /> The laws governing this agreement will be the laws in Papua
            New Guinea and you irrevocably submit to the exclusive jurisdiction
            of its Courts.
            <br />
            For the purposes of this agreement the words “Wan PNG”, “KPHL”,
            “We”, “Our” and “Us” refer to Kumul Petroleum Holdings Limited and
            “Sites” to materials delivered on{' '}
            <b>
              <a
                className="link-text"
                target="_blank"
                href="https://www.wanpng.com"
              >
                https://www.wanpng.com
              </a>
            </b>
            ,{' '}
            <b>
              <a
                className="link-text"
                target="_blank"
                href="https://employer.wanpng.com"
              >
                https://employer.wanpng.com
              </a>
            </b>
            ,{' '}
            <b>
              <a
                className="link-text"
                target="_blank"
                href="https://jobseeker.wanpng.com"
              >
                https://jobseeker.wanpng.com
              </a>
            </b>
            ,{' '}
            <b>
              <a
                className="link-text"
                target="_blank"
                href="https://institution.wanpng.com"
              >
                https://institution.wanpng.com
              </a>
            </b>
            , and other co-branded versions of these Sites.
          </div>
          <div className="spacer-bottom-2" />
          <PrivacyPolicyAndTermAndConditionsFooter />
        </div>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
